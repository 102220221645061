.org-usaid {
  background: no-repeat center url('../img/organizations/usaid.png');
}
.org-redcross {
  background: no-repeat center url('../img/organizations/redcross.png');
}
.org-msf {
  background: no-repeat center url('../img/organizations/msf.png');
}
.org-brc {
  background: no-repeat center url('../img/organizations/brc.png');
}
.org-wb {
  background: no-repeat center url('../img/organizations/wb.png');
}
.org-bing {
  background: no-repeat center url('../img/organizations/bing.png');
}
