/* ==========================================================================
   Typography
   ========================================================================== */

.barlow-condensed {
  font-family: 'Barlow Condensed', sans-serif;;
}
.base-font {
  font-family: "Archivo", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
